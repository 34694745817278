<template>
  <div class="survey">
    <div class="img-wrapper">
      <img src="@/assets/images/404-xl.jpg">
    </div>
    <div class="text-wrapper">
      <span class="md-subtitle">404</span>
      <span class="md-title">Página não encontrada</span>
      <md-button class="button-hp">Regresssar à homepage <md-icon class="icon-menu">keyboard_arrow_right</md-icon></md-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'survey-complete'
}
</script>

<style lang="scss">
.survey {
  margin: 10px;
}
.card-altice {
  margin: auto auto;
  max-width: 1000px;
  min-height: 40vh;
}

.img-wrapper{
  width:100%;
}

@media screen and (max-width:1200px){
  .img-wrapper{
    width:0;
    height:0;
    padding:505px 0 0 1200px;
    background: url(https://www.ptempresas.pt/PublishingImages/Empresas/Paginas/404/404-l.jpg) no-repeat;
  }
  .text-wrapper{
    top:30% !important;
  }
}

@media screen and (max-width:992px){
  .img-wrapper{
    width:0;
    height:0;
    padding:417px 0 0 992px;
    background: url(https://www.ptempresas.pt/PublishingImages/Empresas/Paginas/404/404-m.jpg) no-repeat;
  }
  .text-wrapper{
    top:25% !important;
  }
  .md-subtitle{
  font-size:2.5rem!important;
  }
  .md-title{
  font-size:1.9rem!important;
  }
}

@media screen and (max-width:768px){
  .img-wrapper{
    width:0;
    height:0;
    padding:394px 0 0 768px;
    background: url(https://www.ptempresas.pt/PublishingImages/Empresas/Paginas/404/404-s.jpg) no-repeat;
  }
  .text-wrapper{
    left:40%!important;
    width: fit-content;
  }
  .md-subtitle{
    font-size:2rem!important;
    padding-bottom:5px!important;
  }
  .md-title{
    font-size:1.5rem!important;
    padding-bottom:10px!important;
  }
}

@media screen and (max-width:600px){
  .text-wrapper{
    left:50%!important;
    width: fit-content;
  }
}

@media screen and (max-width:470px){
  .text-wrapper{
    left:60%!important;
    width: fit-content;
  }
}

@media screen and (max-width:361px){
  .text-wrapper{
    left:70%!important;
    width: fit-content;
  }
}

@media screen and (max-width:360px){
  .img-wrapper{
    width:0;
    height:0;
    padding:558px 0 0 360px;
    background: url(https://www.ptempresas.pt/PublishingImages/Empresas/Paginas/404/404-xs.jpg) no-repeat;
  }

  .text-wrapper{
    top:57%!important;
    left:15%!important;
  }
  .md-subtitle{
  font-size:1.5rem!important;
  }
  .md-title{
  font-size:1.2rem!important;
}
}

.text-wrapper{
  z-index:1000;
  position: absolute;
  top:35%;
  left:50%;
  color:white;
  text-align:left;
}

.md-subtitle{
  display: block;
  font-weight:500;
  font-size:2.875rem;
  padding-bottom:10px;
}

.md-title{
  display: block;
  font-weight:700;
  font-size:2.1rem;
  padding-bottom:30px;
}

.md-button{
  margin:0;
}

.button-hp{
  display: block;
  border-radius:5px;
  box-shadow: none!important;
  border: 2px solid white;
  padding: 10px 15px;
  font-size:10px;
  letter-spacing: 0.12em;
  font-weight:700;
  height:50px;
  color:white!important;
  background-color: transparent !important;
  .icon-menu{
    font-size:10px!important;
    color:white!important;
    width:auto!important;
    min-width:auto!important;
  }
}

.button-hp:hover{
  border-radius:5px;
  box-shadow: none!important;
  border-color: #0084D6!important;
  padding: 10px 15px;
  font-size:10px;
  letter-spacing: 0.12em;
  font-weight:700;
  height:50px;
  background-color:#0084D6!important;
  .icon-menu{
    color:white!important;
  }
}

</style>
